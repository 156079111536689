import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import TagManager from 'react-gtm-module';

const root = createRoot(document.getElementById('VolvoGama'));

const tagManagerArgs = {
    gtmId: 'GTM-WQQWKTSZ'
}

TagManager.initialize(tagManagerArgs);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
