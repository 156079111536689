import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import TagManager from 'react-gtm-module';
import Banner from './components/banner/banner';
import Content from './components/content/content';
import WhatsAppButton  from './components/WhatsAppButton';

const TagManagerRoutes = () => {
    const location = useLocation();

    useEffect(() => {
        const tagManagerArgs = {
            dataLayer: {
                event: 'pageview',
                page: location.pathname + location.search
            }
        };
        TagManager.dataLayer(tagManagerArgs);
    }, [location]);

    return null;
};

const App = () => {
    return (
        <Router>
            <WhatsAppButton />
            <TagManagerRoutes />
            <Header />
            <Banner />
            <Content />
            <Footer />
        </Router>
    );
};

export default App;
