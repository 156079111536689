import React from 'react';
import Slider from 'react-slick';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './content.css';

const images = [
    { src: '../slider/1.webp', alt: 'Imagem Principal' },
    { src: '../slider/2.webp', alt: 'Imagem 2' },
    { src: '../slider/3.webp', alt: 'Imagem 3' },
    { src: '../slider/4.webp', alt: 'Imagem 4' },
    { src: '../slider/5.webp', alt: 'Imagem 5' },
    { src: '../slider/7.webp', alt: 'Imagem 7' },
    { src: '../slider/8.webp', alt: 'Imagem 8' },
    { src: '../slider/9.webp', alt: 'Imagem 9' }
];

const Content = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: i => (
            <div className="custom-dot-wrapper">
                <img src={images[i].src} alt={`Thumbnail ${i}`} />
            </div>
        ),
        dotsClass: 'slick-dots custom-dots'
    };

    return (
        <>
            <div className="bg-gris bg-block">
                <div className="container-fluid img-texto bg-block">
                    <div className="img-texto__cTexto bg-flex">
                        <h2 className="img-texto__cTexto__titulo fd bg-block">Por que escolher o <strong>Volvo EX30?</strong></h2>
                    </div>
                    <div className="row img-texto__row bg-relative reversa">
                        <div className="img-texto__row__contenedorImg bg-flex">
                            <div className="img-texto__row__contenedorImg__primero bg-flex">
                                <div className="img-texto__row__contenedorImg__primero__segundo bg-block">
                                    <picture className="img-texto__row__contenedorImg__primero__segundo__img bg-relative bg-block">
                                        <img className="bg-block" src="../banner_meio.webp" alt="Banner Meio" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <div className="img-texto__row__contenedorTexto bg-flex">
                            <div className="img-texto__row__contenedorTexto__1 bg-flex">
                                <div className="img-texto__row__contenedorTexto__1__2 bg-block">
                                    <div className="img-texto__row__contenedorTexto__1__2__3 bg-flex">
                                        <h4 className="img-texto__row__contenedorTexto__1__2__3__titulo Volvo-Novum-Medium fd bg-relative bg-block">Sustentabilidade</h4>
                                        <div className="img-texto__row__contenedorTexto__1__2__3__espacio bg-flex"></div>
                                        <p className="img-texto__row__contenedorTexto__1__2__3__texto fo Volvo-Novum-Light bg-block">
                                            O Volvo EX30 é um veículo elétrico, o que significa que ele não emite poluentes enquanto está em funcionamento, ajudando a reduzir a pegada de carbono e contribuindo para um ambiente mais limpo.
                                        </p>
                                    </div>
                                    <div className="img-texto__row__contenedorTexto__1__2__3 bg-flex" style={{ marginTop: '20px' }}>
                                        <h2 className="img-texto__row__contenedorTexto__1__2__3__titulo Volvo-Novum-Medium fd bg-relative bg-block">Desempenho</h2>
                                        <div className="img-texto__row__contenedorTexto__1__2__3__espacio bg-flex"></div>
                                        <p className="img-texto__row__contenedorTexto__1__2__3__texto fo Volvo-Novum-Light bg-block">
                                            Os veículos elétricos da Volvo são conhecidos por seu desempenho robusto e suave. O EX30 oferece aceleração rápida e uma condução silenciosa e confortável.
                                        </p>
                                    </div>
                                    <div className="img-texto__row__contenedorTexto__1__2__3 bg-flex" style={{ marginTop: '20px' }}>
                                        <h2 className="img-texto__row__contenedorTexto__1__2__3__titulo Volvo-Novum-Medium fd bg-relative bg-block">Segurança</h2>
                                        <div className="img-texto__row__contenedorTexto__1__2__3__espacio bg-flex"></div>
                                        <p className="img-texto__row__contenedorTexto__1__2__3__texto fo Volvo-Novum-Light bg-block">
                                            A Volvo é famosa por seu compromisso com a segurança, e o EX30 não é exceção. Ele vem com uma série de recursos de segurança ativa e passiva para proteger os ocupantes em caso de acidente.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="img-texto__cTexto bg-flex">
                <h2 className="img-texto__cTexto__titulo">Principais informações do modelo</h2>
            </div>
            <div className="statistics-background">
                <div className="statistics-container">
                    <div className="row text-center">
                        <div className="col-6 col-md-3">
                            <h3 className="font-weight-bold">476km</h3>
                            <p>Autonomia elétrica<br/>(Até)</p>
                        </div>
                        <div className="col-6 col-md-3">
                            <h3 className="font-weight-bold">30min</h3>
                            <p>Estimativa de tempo do carregamento rápido DC<br/>(10%-80%)</p>
                        </div>
                        <div className="col-6 col-md-3">
                            <h3 className="font-weight-bold">16.9kWh</h3>
                            <p>Consumo de energia<br/>(por 100km)</p>
                        </div>
                        <div className="col-6 col-md-3">
                            <h3 className="font-weight-bold">5.3s</h3>
                            <p>Aceleração <br/> (0-100 km/h)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container text-center slidergallery">
                <div className="image-gallery">
                    <Slider {...settings}>
                        {images.map((image, index) => (
                            <div key={index}>
                                <img src={image.src} alt={image.alt} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default Content;
