import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './banner.css';

const Banner = () => {
    const [emailError, setEmailError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
    });
    const [showModal, setShowModal] = useState(false);

    const handleEmailBlur = (event) => {
        const email = event.target.value;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(email)) {
            setEmailError("Por favor, insira um e-mail válido.");
        } else {
            setEmailError("");
        }
    };

    const handlePhoneNumberChange = (event) => {
        const value = event.target.value;
        const formattedValue = value.replace(/\D/g, '');
        setPhoneNumber(value);
        setFormData({ ...formData, telefone: formattedValue });
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const mensagem = formData.mensagem.trim() === '' ? 'O contato não informou a mensagem.' : formData.mensagem;
        const [ddd, telefone] = formData.telefone.match(/^(\d{2})(\d{8,9})$/).slice(1);

        const body = {
            nome: formData.nome,
            email: formData.email,
            assunto: 'NOVO LEAD CADASTRADO',
            mensagem: mensagem,
            ddd: ddd,
            telefone: telefone
        };

        try {
            // Envio assíncrono sem esperar a resposta
            axios.post('https://volvogama-api.onrender.com/send', body);
            setShowModal(true);
            setFormData({
                nome: '',
                email: '',
                telefone: '',
                mensagem: ''
            });
            setPhoneNumber('');
        } catch (error) {
            console.error('Erro:', error);
            toast.error('Erro ao enviar o formulário. Por favor, tente novamente.', {
                position: "bottom-left",
                style: {
                    zIndex: 9999,
                },
                transition: Slide,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="container-fluid hero">
            <ToastContainer
                position="bottom-left"
                style={{ zIndex: 9999 }}
                autoClose={5000}
                hideProgressBar
                closeOnClick
                pauseOnHover
            />
            <div className="row hero__row bg-relative">
                <div className="col-12 hero__back">
                    <picture className="hero__back__img bg-relative">
                        <source media="(min-width: 1024px)" srcSet="../banner_volvo.webp" />
                        {/* <source media="(min-width: 768px)" srcSet="../banner_volvo.webp" /> */}
                        {/* <source media="(max-width: 768px)" srcSet="/mnt/data/Gama Volvo LP-1.png" /> */}
                        <img src="../banner_volvo.webp" alt="Hero" />
                    </picture>
                    <div className='hero__texto bg-relative'>
                        <div className="hero__content">
                            <div className="hero__text">
                                <h4><strong>Lançamento</strong></h4>
                                <h2 className="h3Info">Conheça o <strong>Volvo EX30</strong><br /> e <strong>surpreenda-se!</strong></h2>
                            </div>
                            
                            <div className="hero__form">
                                <h2>Fale com um consultor</h2>
                                <p>Estamos prontos para lhe auxiliar!</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        {/* Nome */}
                                        <input type="text" className="form-control input_volvo" id="nome" placeholder='Digite seu nome' required value={formData.nome} onChange={handleChange} />
                                    </div>
                                    <div className="mb-3">
                                        {/* Telefone */}
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            className="form-control input_volvo"
                                            id="telefone"
                                            required
                                            type="tel"
                                            placeholder='Digite seu telefone'
                                            value={phoneNumber}
                                            onChange={handlePhoneNumberChange}
                                        />
                                    </div>
                                    <div className="mb-1">
                                        {/* E-mail */}
                                        <input type="email" className="form-control input_volvo" id="email" placeholder='Digite seu e-mail' required onBlur={handleEmailBlur} value={formData.email} onChange={handleChange} />
                                        {emailError && <div className="text-danger">{emailError}</div>}</div>
                                    {/* Submit */}
                                    <button type="submit" className="btn btn-primary mt-3" disabled={isSubmitting}>
                                        {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Enviar'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal conclusão */}
            <Modal show={showModal} onHide={() => setShowModal(false)} style={{ zIndex: 9999 }} >
                <Modal.Header closeButton>
                    <Modal.Title>Obrigado!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Recebemos sua solicitação e em breve entraremos em contato.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default Banner;
