import React from 'react';
import '../styles.css';
import { Navbar, Container } from 'react-bootstrap';

const Header = () => {
    return (
        <Navbar bg="light" expand="lg" className="container-fluid menu">
            <Container fluid className="d-flex justify-content-center"> {/* Centralizando o container */}
                <Navbar.Brand href="https://volvogama.com.br" className="menu__nombre-dealer__link">
                    <img 
                        src="https://volvogama.com.br/images/volvo-menu.svg" 
                        alt="Volvo" 
                        className="menu__nombre-dealer__logo"
                    />
                    <span className="menu__nombre-dealer__span Volvo-Novum-Regular">|</span>
                    <p className="menu__nombre-dealer__nombre Volvo-Novum-Medium">Gama</p>
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
};

export default Header;
