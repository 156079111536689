import React from 'react';
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/5582982224568?text=Ol%C3%A1!%20Venho%20da%20Landing%20Page%20da%20Volvo%C2%A0Gama."
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button" title='Fale com um consultor pelo Whatsapp'
    >
      <img src="../whatsapp.webp" alt="WhatsApp" />
    </a>
  );
};

export default WhatsAppButton;