import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-block mt-5">
  <div className="footer Volvo-Novum-Light fo bg-flex">
    <div className="footer__contenedor bg-flex fo">
      <span className="footer__contenedor__listaopciones bg-flex fo">
        <span className="footer__contenedor__listaopciones__opcion">
          <a href="https://www.volvocars.com/br/v/legal/cookies" className="footer__contenedor__listaopciones__opcion__link fd bg-block Volvo-Novum-Regular" target="_blank">Cookies</a>
        </span>
        <span className="footer__contenedor__listaopciones__opcion">
          <a href="https://www.volvocars.com/en-br/legal" className="footer__contenedor__listaopciones__opcion__link fd bg-block Volvo-Novum-Regular" target="_blank">Legal</a>
        </span>
        <span className="footer__contenedor__listaopciones__opcion">
          <a href="https://www.volvocars.com/en-br/legal/privacy" className="footer__contenedor__listaopciones__opcion__link fd bg-block Volvo-Novum-Regular" target="_blank">Privacy</a>
        </span>
      </span>
      <span className="footer__contenedor__copyright bg-block">Copyright © 2024 Volvo Car Corporation (ou suas afiliadas ou licenciadoras).</span>
    </div>
  </div>
</footer>

    );
};

export default Footer;
